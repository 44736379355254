<eb-form-item
  [label]="label()"
  [prefixIcon]="icon()"
  [size]="size()"
  [theme]="theme()"
  [onlyIcon]="onlyIcon()"
  suffixIcon="chevron-down"
  [isClearIcon]="!!value"
  (clearInput)="clear()"
>
  @if(value) {
    <div class="eb-control-value ellipsis">
      <ng-container *ngTemplateOutlet="selectedTemplate() ? selectedTemplate() : labelTmpl; context: { $implicit: value }" />
    </div>
  } @else { <!-- search-->
    <input *transloco="let t" type="text" class="eb-input" [placeholder]="t(placeholder())" [formControl]="searchFormControl" />
  }
</eb-form-item>

@if(isLoading()) {
  <div
    #skeleton
    [style.width]="skeletonWidth()"
    [style.left]="skeletonLeft()"
    class="eb-tree-select__skeleton border-1 border-solid"
  >
    <eb-icon key="loading" />
  </div>
}


<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayWidth]="origin.elementRef.nativeElement.offsetWidth"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen()"
  (detach)="close()"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
  (attach)="opened()"
  *transloco="let t"
>
  <div class="eb-overlay-panel hm-60 p-0" #overlayPanel>
    <div class="eb-slide-menu">
      <div class="eb-slide-submenu" [ngClass]="{ 'eb-slide-submenu--active': !activeMenu() }">
        @if(!hasOptions) {
          <div class="eb-slide-menu-item eb-slide-menu-item--readonly">
            <div class="eb-slide-menu-item__label">
              <span class="ellipsis">{{ t('libs.tree-select.noOptionsAvailable') }}</span>
            </div>
          </div>
          <div class="eb-slide-menu-item-divider"></div>
        }

        @for(availableOption of availableOptions; track $index) {
          <div
            class="eb-slide-menu-item"
            [ngClass]="{ 'eb-slide-menu-item--active': activeMenu() === availableOption }"
          >
            <div class="eb-slide-menu-item__label" (click)="activateMenu(availableOption)">
              <span class="ellipsis">
                <ng-container
                  *ngTemplateOutlet="
                    optionTemplate() ? optionTemplate() : labelTmpl;
                    context: { $implicit: availableOption, flat: isSearchResult }
                  "
                />
              </span>
              @if(!isSearchResult && availableOption.items?.length) {
                <eb-icon key="chevron-right" size="mde" class="ml-auto" />
              }
            </div>
            <div class="eb-slide-submenu" [ngClass]="{ 'eb-slide-submenu--active': activeMenu() === availableOption }">
              <div class="eb-slide-menu-item sticky top-0 bg-white" (click)="activateMenu(null)">
                <div class="eb-slide-menu-item__label">
                  <eb-icon key="chevron-left" size="mde" class="mr-3" />
                  <span class="ellipsis">
                    <ng-container
                      *ngTemplateOutlet="
                        optionTemplate() ? optionTemplate() : labelTmpl;
                        context: { $implicit: availableOption, flat: isSearchResult }
                      "
                    />
                  </span>
                </div>
              </div>
              <div class="eb-divider"></div>
              <div class="eb-slide-menu-item">
                <div class="eb-slide-menu-item__label" (click)="select(availableOption)">
                  <span class="ellipsis">{{ t('libs.tree-select.allFrom') }} {{ availableOption.label }}{{ allFromCategory() }}</span>
                </div>
              </div>
              @for(option of availableOption.items; let i = $index; track i) {
                <div class="eb-slide-menu-item" (click)="select(option)">
                  <div class="eb-slide-menu-item__label">
                    <span class="ellipsis">
                      <ng-container
                        *ngTemplateOutlet="optionTemplate() ? optionTemplate() : labelTmpl; context: { $implicit: option, flat: isSearchResult }"
                      />
                    </span>
                  </div>
                  @if(option.mostlySelected && !availableOption.items?.[i + 1]?.mostlySelected) {
                    <div class="eb-divider"></div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #labelTmpl let-option let-flat="flat">
  @if(flat && option.parent) {
    {{ option.parent.label }} /
  }
  {{ option.label }}
</ng-template>
