/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    InputSignal,
    forwardRef,
    inject,
    input,
    viewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITreeOption, UiTreeSelectModule } from '@e-bilet/ui-tree-select';
import { TranslocoModule } from '@jsverse/transloco';
import { EbTreeSelectComponent } from 'libs/ui-tree-select/src/lib/tree-select/tree-select.component';
import { Province } from '../../../rest-api/models/province.model';
import {
    EbInputSize,
    EbInputTheme,
} from './../../../../../../../libs/ui-form-item/src/lib/form-item/form-item.component';

@Component({
    selector: 'eb-location-tree-select',
    templateUrl: './location-tree-select.component.html',
    styleUrls: ['./location-tree-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbLocationTreeSelectComponent), multi: true },
    ],
    standalone: true,
    imports: [CommonModule, UiTreeSelectModule, TranslocoModule, FormsModule],
})
export class EbLocationTreeSelectComponent implements ControlValueAccessor {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    public readonly elementRef = inject(ElementRef);

    private _selected: ITreeOption<Province> | null = null;

    protected get selected(): ITreeOption<Province> | null {
        return this._selected;
    }

    options: InputSignal<ITreeOption<Province>[]> = input.required();
    onlyIcon: InputSignal<boolean> = input(false);
    size: InputSignal<EbInputSize> = input<EbInputSize>('default');
    theme: InputSignal<EbInputTheme> = input<EbInputTheme>('dark');
    origin: InputSignal<CdkOverlayOrigin | undefined> = input<CdkOverlayOrigin | undefined>();
    formElement: InputSignal<HTMLFormElement | undefined> = input<HTMLFormElement | undefined>();

    @HostBinding('class.eb-location-tree-select') protected get isLocationTreeSelect(): boolean {
        return true;
    }

    readonly ebTreeSelectComponent = viewChild<EbTreeSelectComponent<any>>(EbTreeSelectComponent<any>);

    private _onTouched: () => void = () => {};
    private _onChange: (value: ITreeOption<Province> | null) => void = () => {};

    public writeValue(value: ITreeOption<Province> | null): void {
        this._selected = value;
        this._changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    protected select(value: ITreeOption<Province> | null): void {
        this._selected = value;
        this._onChange(this._selected);
    }
}
