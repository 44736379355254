export const MOST_POPULAR_CITIES: string[] = [
    'Wrocław',
    'Bydgoszcz',
    'Toruń',
    'Lublin',
    'Gorzów Wielkopolski',
    'Zielona Góra',
    'Łódź',
    'Kraków',
    'Warszawa',
    'Opole',
    'Rzeszów',
    'Białystok',
    'Gdańsk',
    'Gdynia',
    'Sopot',
    'Katowice',
    'Chorzów',
    'Gliwice',
    'Kielce',
    'Olsztyn',
    'Poznań',
    'Szczecin',
];
