import { InputSignal, input, viewChild } from '@angular/core';
/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    PLATFORM_ID,
    TemplateRef,
    ViewEncapsulation,
    WritableSignal,
    forwardRef,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvgIcons } from '@ngneat/svg-icon';
import { EbInputSize, EbInputTheme, FormItemComponent } from 'libs/ui-form-item/src/lib/form-item/form-item.component';
import { ReplaySubject, combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ITreeOption } from './tree-option.interface';
import { TreeSelectHelper } from './tree-select.helper';

@Component({
    selector: 'eb-tree-select',
    templateUrl: './tree-select.component.html',
    styleUrls: ['./tree-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbTreeSelectComponent), multi: true }],
})
export class EbTreeSelectComponent<T> implements ControlValueAccessor {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    public readonly elementRef = inject(ElementRef);

    private readonly _options$ = new ReplaySubject<ITreeOption<T>[]>(1);
    private _availableOptions: WritableSignal<ITreeOption<T>[]> = signal<ITreeOption<T>[]>([]);
    private _hasOptions: WritableSignal<boolean> = signal(false);
    private _value: ITreeOption<T> | null = null;
    private _originEl: CdkOverlayOrigin;
    private _origin: CdkOverlayOrigin;
    activeMenu: WritableSignal<ITreeOption<T> | null> = signal<ITreeOption<T> | null>(null);

    protected get value(): ITreeOption<T> | null {
        return this._value;
    }

    protected get hasOptions(): boolean {
        return this._hasOptions();
    }

    protected get isSearchResult(): boolean {
        return !!this.searchFormControl.value && this.hasOptions;
    }

    protected get availableOptions(): ITreeOption<T>[] {
        return this._availableOptions();
    }

    searchFormControl = new FormControl();
    isOpen: WritableSignal<boolean> = signal(false);
    isLoading: WritableSignal<boolean> = signal(false);
    skeletonWidth: WritableSignal<string> = signal('0px');
    skeletonLeft: WritableSignal<string> = signal('0px');

    @Input({ required: true }) public set options(items: ITreeOption<T>[]) {
        this._options$.next(items);
    }

    icon: InputSignal<SvgIcons | undefined> = input<SvgIcons | undefined>(undefined);
    label: InputSignal<string> = input('');
    placeholder: InputSignal<string> = input('libs.tree-select.select');
    size: InputSignal<EbInputSize> = input<EbInputSize>('default');
    theme: InputSignal<EbInputTheme> = input<EbInputTheme>('dark');
    onlyIcon: InputSignal<boolean> = input(false);
    allFromCategory: InputSignal<string> = input('');
    selectedTemplate: InputSignal<TemplateRef<any> | null> = input<TemplateRef<any> | null>(null);
    optionTemplate: InputSignal<TemplateRef<any> | null> = input<TemplateRef<any> | null>(null);
    customValue: InputSignal<boolean> = input(false);
    formElement: InputSignal<HTMLFormElement | undefined> = input<HTMLFormElement | undefined>(undefined);
    @Input() compareFn: (a: ITreeOption<T>, b: ITreeOption<T>) => number = this._defaultCompererFn;

    @Input() public set origin(origin: CdkOverlayOrigin | undefined) {
        if (origin) {
            this._origin = origin;
        } else {
            this._origin = this._originEl;
        }
    }

    public get origin(): CdkOverlayOrigin {
        return this._origin;
    }

    readonly overlayPanel = viewChild.required<ElementRef>('overlayPanel');
    readonly formItemComponent = viewChild.required<FormItemComponent>(FormItemComponent);

    @HostBinding('class.eb-tree-select') protected get isPicker(): boolean {
        return true;
    }

    @HostListener('click') protected onClickInputBox(): void {
        if (!this.isOpen()) {
            this.open();
        }
    }

    private _onTouched = () => {};
    private _onChange: (value: ITreeOption<T> | null) => void = () => {};

    constructor() {
        this._originEl = new CdkOverlayOrigin(this.elementRef);
        this._origin = this._originEl;

        combineLatest([this.searchFormControl.valueChanges.pipe(startWith(null)), this._options$])
            .pipe(takeUntilDestroyed())
            .subscribe(([searchPhrase, options]) => {
                if (searchPhrase) {
                    this._availableOptions.set(this._filter(searchPhrase, options));
                    if (this._availableOptions().length > 0) {
                        this._hasOptions.set(true);
                    } else {
                        this._hasOptions.set(false);
                        this._availableOptions.set(options);
                    }
                } else {
                    this._hasOptions.set(true);
                    this._availableOptions.set(options);
                }
                this.activeMenu.set(null);
            });
    }

    public writeValue(obj: ITreeOption<T>): void {
        if (this._value !== obj) {
            this._value = obj;
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    protected select(item: ITreeOption<T>): void {
        this._value = item;
        this._onChange(this._value);
        this.close();
    }

    protected clear(): void {
        this._value = null;
        this._onChange(this._value);
        this.searchFormControl.reset();
        this.close();
    }

    protected onOverlayOutsideClick(event: MouseEvent): void {
        if (!(this.elementRef.nativeElement as any).contains(event.target)) {
            this.close();
        }
    }

    protected open(): void {
        this._setSkeletonPlacement();
        this.isLoading.set(true);

        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => {
                this.isOpen.set(true);
            });
        } else {
            this.isOpen.set(true);
        }
    }

    public close(): void {
        if (!this.customValue) {
            this.searchFormControl.reset();
        }
        this.isOpen.set(false);
    }

    public invokeClick(): void {
        this.elementRef.nativeElement.click();
    }

    protected activateMenu(option: ITreeOption<T> | null): void {
        if (this.isSearchResult && option) {
            this.select(option);
        } else {
            this.activeMenu.set(option);
        }
    }

    protected opened(): void {
        this.isLoading.set(false);
        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => this._changeDetectorRef.markForCheck());
        } else {
            this._changeDetectorRef.markForCheck();
        }
    }

    private _filter(searchPhrase: string, options: ITreeOption<T>[]): ITreeOption<T>[] {
        const lowerPhrase = searchPhrase.toLowerCase();
        const flatOptions = TreeSelectHelper.flatOptions(options);
        return flatOptions
            .filter((o) => o.label.toLowerCase().includes(lowerPhrase))
            .sort((a, b) => this.compareFn(a, b));
    }

    private _defaultCompererFn(a: ITreeOption<T>, b: ITreeOption<T>): number {
        return this._getFlatLabel(a).localeCompare(this._getFlatLabel(b), 'pl', { ignorePunctuation: true });
    }

    private _getFlatLabel(option: ITreeOption<T>): string {
        if (option.parent) {
            return option.parent.label + ' / ' + option.label;
        } else {
            return option.label;
        }
    }

    private _setSkeletonPlacement(): void {
        this._alignSkeletonToFormField();
        this._setSkeletonWidth();
    }

    private _alignSkeletonToFormField(): void {
        this.skeletonLeft.set(
            this.onlyIcon() && this.formElement
                ? `${this.formElement()!.offsetLeft}px`
                : `${this.formItemComponent()?.elementRef.nativeElement.getBoundingClientRect().x}px`,
        );
    }

    private _setSkeletonWidth(): void {
        this.skeletonWidth.set(
            this.onlyIcon() && this.formElement
                ? `${this.formElement()!.offsetWidth}px`
                : `${this.formItemComponent()?.elementRef.nativeElement.offsetWidth}px`,
        );
    }
}
