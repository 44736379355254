import { Injectable, inject } from '@angular/core';
import { ITreeOption, TreeSelectMapper } from '@e-bilet/ui-tree-select';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HomeRestService } from '../rest-api/home-rest.service';
import { Province } from '../rest-api/models/province.model';
import { MOST_POPULAR_CITIES } from './../../../../../libs/ui-tree-select/src/lib/tree-select/tree-option.const';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class LocalizationTreeStoreService extends BaseStoreService<null, ITreeOption<Province>[]> {
    private readonly _homeRestService = inject(HomeRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<ITreeOption<Province>[]> {
        return () =>
            this._homeRestService.getCitiesTree().pipe(
                map((provinces) =>
                    TreeSelectMapper.map(
                        provinces || [],
                        (p) => p.name,
                        (p) => p.cities as Province[],
                    ),
                ),
                map((provinces) => this._sortCitiesByTopChoice(provinces)),
            );
    }

    public load(): Observable<ITreeOption<Province>[]> {
        return super.load(null);
    }

    public get(): Observable<ITreeOption<Province>[]> {
        this.load();
        return super.get();
    }

    public find$(province?: string, city?: string): Observable<ITreeOption<Province> | null> {
        return this.get().pipe(
            map((locationTree) => this._find(locationTree || [], province, city)),
            take(1),
        );
    }

    private _find(
        locationTree: ITreeOption<Province>[],
        province?: string,
        citySlug?: string,
    ): ITreeOption<Province> | null {
        if (province && locationTree.length) {
            const provinceTreeOption = locationTree.find((l) => l.value.slug === province);
            if (provinceTreeOption) {
                if (citySlug) {
                    return provinceTreeOption.items?.find((c) => c.value.slug === citySlug) || null;
                }
                return provinceTreeOption;
            }
        }

        return null;
    }

    private _sortCitiesByTopChoice(locations: ITreeOption<Province>[]): ITreeOption<Province>[] {
        locations.forEach((province) => {
            province.items = province.items?.reduce((acc, current) => {
                if (MOST_POPULAR_CITIES.includes(current.label)) {
                    return [{ ...current, mostlySelected: true }, ...acc];
                }
                return [...acc, current];
            }, [] as ITreeOption<Province>[]);
        });
        return locations;
    }
}
