import { ITreeOption } from './tree-option.interface';

export class TreeSelectHelper {
    static flatOptions<T>(options: ITreeOption<T>[]) {
        const flatMainOptions = options || [];
        const flatSubOptions = (options || []).map((o) => o.items || []);
        return flatMainOptions.concat(...flatSubOptions);
    }

    static copy<T>(options: ITreeOption<T>[]): ITreeOption<T>[] {
        return (options || []).map((i) => {
            const option: ITreeOption<T> = {
                label: i.label,
                value: { ...i.value },
                items: [],
            };
            option.items = (i.items || []).map((subitem) => ({
                label: subitem.label,
                value: { ...subitem.value },
                parent: option,
            }));
            return option;
        });
    }
}
